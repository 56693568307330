@use "../config/" as *;
.waf-contest {
    &.waf-shape-head {
        .waf-head,
        .article-head {
            @extend %px-0;
        }
    }
    iframe {
        @extend %w-100;
    }
    .contest {
        &-wrapper {
            @extend %mt-6;
        }
        &-banner {
            min-height: 19rem;
            @include background("cssimages/content-page/content-head-bg-mob.png", center/cover no-repeat);
            @extend %white;
            @extend %flex-column-c-c;
            @extend %p-4;
            @extend %half-radius;
            @extend %text-center;
            @extend %mb-4;
        }
        &-content {
            @extend %white-bg;
            @extend %px-3;
            @extend %py-5;
            @extend %mb-4;
            .title {
                @extend %secondary-dark;
                @extend %font-18-pb;
                @extend %mb-4;
            }
            .sub-title {
                @extend %font-14-pb;
                @extend %neutral;
            }
            p,
            .text {
                @extend %neutral;
                @extend %font-14-pr;
                &:not(:last-child) {
                    @extend %mb-3;
                }
            }
            a {
                @extend %font-14-pb;
                @extend %primary;
            }
        }
        &-video-title {
            @extend %font-18-pb;
            @extend %text-center;
            @extend %capitalize;
            @extend %mt-3;
        }
        &-poll {
            background-image: linear-gradient(to right,
                    var(--secondary-light) 0%,
                    var(--secondary) 50%,
                    var(--secondary-dark) 100%);
            @extend %white;
            @extend %px-3;
            @extend %py-4;
            @extend %half-radius;
            .title {
                @extend %white;
                @extend %font-16-pb;
                @extend %text-center;
            }
        }
        &-thankyou {
            @extend %mt-6;
            @extend %relative;
        }
        &-thankyou-content {
            @extend %text-center;
            @extend %mt-4;
            .thankyou-icon {
                height: 6rem;
                width: 6rem;
                aspect-ratio: 1 / 1;
                object-fit: contain;
                @extend %mx-auto;
                @extend %mb-2;
            }
            .thankyou-title {
                @extend %font-34-pb;
                @extend %mb-3;
            }
            .thankyou-text {
                @extend %font-16-pr;
                @extend %mb-4;
            }
            .btn-back {
                @extend %d-inline-block;
            }
        }
        &-already-participated {
            @extend %text-center;
            @extend %p-4;
        }
    }
    .banner {
        &-title {
            @extend %font-24-pb;
            @extend %uppercase;
        }
        &-sub-title {
            @extend %font-12-pr;
        }
    }
    .poll {
        &-head {
            .title {
                @extend %mb-5;
            }
        }
        &-body {
            .btn-submit {
                @extend %mt-5;
                @extend %flex-c-n;
            }
        }
    }
    .radio {
        /* Hide default radio button */
        &-input {
            @extend %d-none;
        }
        /* Custom radio button styling */
        &-container {
            cursor: pointer;
            @extend %flex-n-c;
            @extend %font-14-pr;
            @extend %white-bg-1;
            @extend %px-4;
            @extend %py-2;
            @extend %half-radius;
            .radiomark {
                width: 2rem;
                height: 2rem;
                border: .1rem solid clr(white);
                @extend %relative;
                @extend %mr-2;
                @extend %circle-radius;
            }
        }
        &-box-wrap {
            display: grid;
            // @extend %flex-column;
            @extend %gap-6;
        }
        /* Checked state styling */
        &-input:checked + .radiomark {
            @extend %primary-bg;
        }
        /* Dot inside the radio when selected */
        &-input:checked + .radiomark::after {
            @include icon(checkmark, 10);
            @extend %white;
            @extend %position-center;
        }
    }
    .login-redirect {
        @extend %mt-4;
        .text {
            @extend %font-14-pr;
        }
        .btn-site {
            @extend %px-2;
            @extend %py-1;
        }
    }
}
.confetti {
    z-index: var(--z-negative);
    top: -1rem;
    @extend %absolute;
    &-animation-slow {
        animation: confetti-slow 2.25s linear 1 forwards;
    }
    &-animation-medium {
        animation: confetti-medium 1.75s linear 1 forwards;
    }
    &-animation-fast {
        animation: confetti-fast 1.25s linear 1 forwards;
    }
    &-wrapper {
        z-index: var(--z-negative);
        width: var(--content-width);
        @include position(0, 0, 0, var(--container-white-space-neg));
        @extend %hidden;
    }
}
@include mq(col-lg) {
    .waf-contest {
        .contest {
            &-banner {
                min-height: 20rem;
                @include background("cssimages/content-page/content-head-bg-web.png", var(--secondary-light) center/cover no-repeat);
            }
            &-video-title {
                margin-block: var(--space-4);
            }
            &-poll {
                padding-block: var(--space-10);
                @include background("cssimages/content-page/contest-poll-bg-web.png", var(--secondary-light) center/cover no-repeat);
            }
        }
        .banner {
            &-title {
                font-size: 3.6rem;
            }
            &-sub-title {
                font-size: 1.6rem;
            }
        }
        .poll {
            &-section {
                width: 80%;
                margin-inline: auto;
            }
            &-body {
                .btn-submit {
                    margin-top: var(--space-8);
                }
            }
        }
        .contest {
            &-content {
                padding: var(--space-6);
            }
            &-poll {
                .title {
                    margin-bottom: var(--space-8);
                }
            }
            &-thankyou-wrapper {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                padding-block: var(--space-20);
            }
        }
        .radio {
            &-box-wrap {
                grid-template-columns: repeat(2, 1fr);
            }
        }
        .login-redirect {
            text-align: right;
        }
    }
}